import React, { useRef, useEffect, useState } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import NorthstarPageLayout from '../../../components/NorthstarPageLayout';
import northstarClient from '../../../services/northstarClient';

// The wrapper exports only a default component that at the same time is a
// namespace for the related Props interface (HighchartsReact.Props) and
// RefObject interface (HighchartsReact.RefObject). All other interfaces
// like Options come from the Highcharts module itself.

const Reports = (props: HighchartsReact.Props) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const GATSBY_API_URL = process.env.GATSBY_API_URL;
    const [dataReport, setDataReport] = useState([]);
    const [reportName, setReportName] = useState("")

    const getReport = async () => {
        const queryParams = new URLSearchParams(window.location.search)
        const reportId = queryParams.get("id")
        const report = await northstarClient.get(`${GATSBY_API_URL}/api/northstar-publication/get-report?id=${reportId}`);
        for (const set in report.datasetTwo.notebook_output.result.datasets) {
            report.datasetOne.notebook_output.result.datasets[set] = report.datasetTwo.notebook_output.result.datasets[set]
        }
        setDataReport(report.datasetOne?.notebook_output?.result?.datasets || []);
        setReportName(report.name || "");
    };

    useEffect(() => {
        getReport();
        Highcharts.setOptions({
            lang: {
                thousandsSep: ","
            }
        })
    }, [])

    const generateReports = () => {
        const highChartReports = [];

        for (const report in dataReport) {
            const highChartReport: Highcharts.Options = {
                title: {
                    text: dataReport[report].name
                },
                series: [],
                xAxis: {
                    title: {
                        text: dataReport[report].xAxisLabel
                    },
                    type: dataReport[report].type === "time" ? 'datetime' : 'linear',
                    tickInterval: dataReport[report].type === "time" ? 28 * 24 * 3600 * 1000 * 12 : 12
                },
                yAxis: {
                    title: {
                        text: dataReport[report].yAxisLabel
                    }
                },
                chart: {
                    height: '600px',
                },
                plotOptions: {
                    series: {
                        marker: {
                            enabled: false,
                            radius: 2
                        }
                    }
                },
                tooltip: {
                    valuePrefix: dataReport[report].unit || null 
                },
                credits:{
                    enabled: false
                }
            }

            if (dataReport[report].type === "time") {
                highChartReport.xAxis['labels'] = {}
                highChartReport.xAxis['labels']['format'] = '{value:%b %Y}';
            }

            let currentCohort = dataReport[report]['data'][0]['cohort']
            let series = {
                name: new Date(currentCohort).toLocaleString('default', { month: 'short' }) + " " + new Date(currentCohort).getFullYear(),
                type: 'spline',
                data: [],
                zoneAxis: 'x',
                zones: [{
                    value: null
                }, {
                    dashStyle: 'Dot'
                }],
            }
            let total = 0;

            for (const row of dataReport[report]['data']) {
                if (row['cohort'] !== currentCohort) {
                    highChartReport.series.push(series)
                    currentCohort = row['cohort'];
                    total = 0;
                    series = {
                        name: new Date(currentCohort).toLocaleString('default', { month: 'short' }) + " " + new Date(currentCohort).getFullYear(),
                        type: 'spline',
                        data: [],
                        zoneAxis: 'x',
                        zones: [{
                            value: null
                        }, {
                            dashStyle: 'Dot'
                        }]
                    }
                }

                let xValues = dataReport[report].type === "time" ? Date.parse(new Date(row['date'])) : row['periods_since_acquisition']

                if (row['actual']) {
                    total += row['actual']
                }
                else {
                    if (series.zones[0].value == null) series.zones[0].value = xValues;
                    total += row['expected'];
                }

                series.data.push([xValues, Math.round(total)]);
            }

            highChartReports.push(
                <div key={report}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={highChartReport}
                        ref={chartComponentRef}
                        {...props}
                    />
                </div>
            );
        }
        return highChartReports;

    }

    return (
        <>
            <NorthstarPageLayout title={reportName}>
                <div>
                    <div className='text-center mb-5 font-bold w-full text-lg md:text-xl my-6'>{reportName}</div>
                    <div className="grid xl:grid-cols-2 gap-6">
                        {
                            generateReports()
                        }
                    </div>
                </div>
            </NorthstarPageLayout>
        </>
    );
};

export default Reports;